<template>
  <gw-dialog v-model="dialog" @close:dialog="closeView">
    <firm-preview :item="obModel.firm" />
    <template #prepend-actions>
      <v-btn
        :to="{ name: 'customers.update', params: { id: obModel.id } }"
        text
        color="primary"
      >
        <icon-edit size="1.2em" class="mr-2" />
        {{ $t("edit") }}
      </v-btn>
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import CustomersMixin from "@/modules/customers/mixins/CustomersMixin";

import GwDialog from "@/components/common/GwDialog.vue";
import FirmPreview from "@/modules/companies/components/FirmPreview.vue";

@Component({
  components: { GwDialog, FirmPreview },
})
export default class CustomerPreviewDialog extends Mixins(CustomersMixin) {
  @PropSync("open", { type: Boolean, default: false }) dialog!: boolean;
}
</script>
