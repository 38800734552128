<template>
  <v-list two-line>
    <v-row no-gutters>
      <template v-for="(arItem, index) in arItems">
        <v-col :key="`firm.preview.${index}`" cols="12" lg="6">
          <template v-for="arFirmData in arItem">
            <v-list-item :key="`firm.data.${arFirmData[0]}`">
              <v-list-item-content>
                <v-list-item-title v-text="arFirmData[1]" />
                <v-list-item-subtitle v-text="arFirmData[0]" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-col>
      </template>
      <v-col cols="12" lg="6"></v-col>
    </v-row>
  </v-list>
</template>

<script lang="ts">
import { dotcase } from "@/plugins/helpers";
import { LocationModule } from "@/store/location";
import type { FirmData } from "@planetadeleste/vue-mc-gw";
import type { Dictionary } from "lodash";
import {
  camelCase,
  chain,
  chunk,
  endsWith,
  get,
  isEmpty,
  isNil,
  upperFirst,
} from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FirmPreview extends Vue {
  @Prop(Object) readonly item!: Partial<FirmData>;

  get sDocType() {
    if (!this.item.doc_type) {
      return "";
    }

    return ["rut", "ci"].includes(this.item.doc_type)
      ? this.$t(`doctype.${this.item.doc_type}`)
      : this.$t("doctype.unknown");
  }

  get sDocId() {
    return this.item.doc_formatted;
  }

  get sCountryCode() {
    if (!this.item.country_id) {
      return null;
    }

    const obCountries = LocationModule.countries;
    const obCountry = obCountries.find({ id: this.item.country_id });
    return obCountry ? obCountry.name : null;
  }

  get sStateCode() {
    if (!this.item.state_code && !this.item.state_id) {
      return null;
    }

    const obFind = this.item.state_id
      ? { id: this.item.state_id }
      : { code: this.item.state_code };
    const obState = LocationModule.states.find(obFind);

    return obState ? obState.name : null;
  }

  get sTownSlug() {
    if (!this.item.town_id && !this.item.town_slug) {
      return null;
    }

    const obFind = this.item.town_id
      ? { id: this.item.town_id }
      : { slug: this.item.town_slug };
    const obTown = LocationModule.towns.find(obFind);

    return obTown ? obTown.name : null;
  }

  get arItems() {
    // @ts-ignore
    const arValues = chain(this.item)
      .omitBy((obItem, sKey) => {
        return (
          isNil(obItem) ||
          isEmpty(obItem) ||
          ["id", "slug", "doc_formatted"].includes(sKey)
        );
      })
      .mapValues(this.mapFirmValue)
      // @ts-ignore
      .mapKeys(this.mapFirmKey)
      .toPairs()
      .value();

    return chunk(arValues, 2);
  }

  mounted() {
    if (this.item.country_id) {
      LocationModule.loadStates(this.item.country_id).then(() => {
        if (this.item.state_id) {
          LocationModule.loadTowns(this.item.state_id);
        }
      });
    }
  }

  mapFirmValue<T = FirmData>(sValue: T[keyof T], sKey: string) {
    const sMethod = `s${upperFirst(camelCase(sKey))}`;
    const sMappedValue: any = get(this, sMethod, sValue);

    return sMappedValue;
  }

  mapFirmKey<T = FirmData>(
    sValue: T[keyof T],
    sKey: string
  ): Dictionary<T[keyof T]> {
    if (endsWith(sKey, "_id")) {
      sKey = sKey.slice(0, -3);
    }

    // @ts-ignore
    return this.$t(dotcase(sKey));
  }
}
</script>
